import { Component } from '@angular/core';
import {VehicleModel} from '../../../models/profile/vehicle.model';
import {ModalController, NavController} from '@ionic/angular';
import {route} from '../../../environments/route';
import {LoginService} from '../../login/services/login.service';
import {TranslationService} from '../../services/translation.service';
import {BesaasResponse} from '../../services/api.service';
import {ToastService} from '../../services/toast.service';
import {Events} from '../../services/Events';
import {ErrorManagerServiceService} from '../../services/errorManagerService.service';
import {AddVehicleService} from './services/addVehicle.service';

@Component({
  selector: 'app-add-vehicle-page',
  templateUrl: './add-vehicle-page.page.html',
  styleUrls: ['./add-vehicle-page.page.scss'],
})
export class AddVehiclePagePage {

  showLoader = false;
  vehicle = new VehicleModel();
  disableSubmit = false;

  translations = {
    title: '',
    submit_btn: '',
    plate: '',
    vehicle_add_successfull: '',
    form_invalid: ''
  };

  constructor(
      private navController: NavController,
      private modalController: ModalController,
      private loginService: LoginService,
      private translationService: TranslationService,
      private toastService: ToastService,
      private events: Events,
      private errorManagerServiceService: ErrorManagerServiceService,
      private addVehicleService: AddVehicleService
  ) { }

  ionViewDidEnter(): void {

    if (!this.loginService.isLogged()) {
      this.navController.navigateRoot(route.login);
    }

    this.translationService.loadTranslation('addplate', this.translations);
  }

  public dismissModal() {
    this.modalController.dismiss();
  }

  public processForm() {
    this.showLoader = true;

    if(this.disableSubmit === false) {
      this.disableSubmit = true;

      if (this.isFormValid()) {
        this.addVehicleService.postAddVehicle(this.vehicle).subscribe((response: BesaasResponse) => {
          this.showLoader = false;
          this.disableSubmit = false;
          this.modalController.dismiss();
          this.toastService.presentToast(this.translations.vehicle_add_successfull);
          this.events.publish('reload_profile');
        }, (response) => {
          this.showLoader = false;
          this.disableSubmit = false;
          this.errorManagerServiceService.handle(response);
        });
        this.showLoader = false;
      } else {
        this.disableSubmit = false;
        this.showLoader = false;
        this.toastService.presentToast(this.translations.form_invalid);
      }
    }
  }

  private isFormValid() {
    if (this.vehicle.plate.length < 1 || this.vehicle.plate.length === 0 || this.vehicle.plate.length >= 45) {
      return false;
    }

    return true;
  }
}
