import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ApiService} from '../../../services/api.service';
import {StorageService} from '../../../services/storage.service';
import {VehicleModel} from '../../../../models/profile/vehicle.model';

@Injectable({
  providedIn: 'root'
})

export class AddVehicleService {

  constructor(
      private api: ApiService,
      private http: HttpClient,
      private storageService: StorageService,
  ) { }

 public postAddVehicle(vehicle: VehicleModel) {
      return this.http.post(
          this.api.getHostVersion() + '/organisation/' + this.storageService.getCurrentOrganisation() + '/profile/me/vehicle',
          {plate: vehicle.plate},
          {headers: this.api.getHeaders()});
 }
}